import React from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Hidden } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { transparentize } from 'polished';
import Picture from '@c/Picture';

const useStyles = makeStyles(theme => ({
  label: {
    fontSize: '0.75rem',
    fontWeight: 600,
    textTransform: 'uppercase',
    lineHeight: 1.33,
    letterSpacing: '0.5px',
    color: transparentize(0.32, theme.colors.dark),
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.625rem',
      lineHeight: 1.2,
      letterSpacing: '0.4px',
    },
  },
  name: {
    fontSize: '1.75rem',
    lineHeight: 'normal',
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.375rem',
      lineHeight: 1.27,
      marginBottom: 0,
    },
  },
  description: {
    fontSize: '1.0625rem',
    lineHeight: 1.65,
    margin: 0,
    marginRight: theme.spacing(32),
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.9375rem',
      lineHeight: 1.6,
      marginRight: theme.spacing(0),
      marginTop: theme.spacing(3),
    },
  },
  button: {
    [theme.breakpoints.up('md')]: {
      '& + &': {
        marginTop: theme.spacing(1),
      },
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(3),
      padding: `${theme.spacing(1.5)}px ${theme.spacing(2)}px`,
    },
  },
  photo: {
    width: '80px',
    [theme.breakpoints.down('sm')]: {
      width: '56px',
    },
  },
}));

const AuthorInfo = ({ data }) => {
  const classes = useStyles();

  if (!data) return null;

  return (
    <div>
      <Box display='flex' alignItems={{ xs: 'center', md: 'flex-start' }}>
        {data.photo && (
          <Picture
            borderRadius='50%'
            alt={data.fullName}
            className={classes.photo}
            {...data.photo}
          />
        )}
        <Box width={1} ml={{ xs: data.photo ? 2 : 0, md: data.photo ? 3 : 0 }}>
          <div className={classes.label}>Skrevet av{/* Fetch from backend? */}</div>
          <Box display='flex' alignItems='center' justifyContent='space-between'>
            <h4 className={classes.name}>{data.fullName}</h4>
            <Hidden smDown>
              <Box display='flex' flexDirection='column'>
                {data.socialLinks.map(socialLink => (
                  <Button
                    variant='outlined'
                    size='small'
                    className={classes.button}
                    href={socialLink.linkUrl}
                    key={socialLink.id}
                  >
                    {socialLink.linkLabel}
                  </Button>
                ))}
              </Box>
            </Hidden>
          </Box>
          {data.description && (
            <Hidden smDown>
              <p className={classes.description}>{data.description}</p>
            </Hidden>
          )}
        </Box>
      </Box>
      <Hidden mdUp>
        <div>
          {data.description && <p className={classes.description}>{data.description}</p>}
          {data.socialLinks.map(socialLink => (
            <Button
              variant='outlined'
              size='small'
              fullWidth
              className={classes.button}
              href={socialLink.linkUrl}
              key={socialLink.id}
            >
              {socialLink.linkLabel}
            </Button>
          ))}
        </div>
      </Hidden>
    </div>
  );
};

export default AuthorInfo;

AuthorInfo.propTypes = {
  data: PropTypes.object,
};

AuthorInfo.defaultProps = {
  data: null,
};
