import React from 'react';
import PropTypes from 'prop-types';
import { Container, Grid, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Divider from '@c/Divider';
import FeaturedContent from '@c/FeaturedContentGrid/FeaturedContent';

const useStyles = makeStyles(theme => ({
  title: {
    fontSize: '0.75rem',
    fontWeight: 600,
    textTransform: 'uppercase',
    lineHeight: 1.33,
    letterSpacing: '0.5px',
    textAlign: 'center',
  },
  grid: {
    marginTop: '2.5vw',
    [theme.breakpoints.down('sm')]: {
      marginTop: '5vw',
    },
  },
}));

const RelatedEntries = ({ title, entries }) => {
  const classes = useStyles();

  if (!entries.length) return null;

  return (
    <>
      <Divider mb={{ xs: 7, md: 12 }} />
      <Container>
        <Box mb={{ xs: 4.5, md: 9.5 }}>
          {title && <div className={classes.title}>{title}</div>}
          <Grid container spacing={5}>
            {entries.map((entry, index) => (
              <FeaturedContent smallGrid entry={entry} key={index} className={classes.grid} />
            ))}
          </Grid>
        </Box>
      </Container>
    </>
  );
};

export default RelatedEntries;

RelatedEntries.propTypes = {
  title: PropTypes.string,
  entries: PropTypes.array.isRequired,
};

RelatedEntries.defaultProps = {
  title: null,
};
