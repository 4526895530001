import React, { useCallback } from 'react';
import { graphql, Link } from 'gatsby';
import { transparentize } from 'polished';
import { Container, Grid, Box, Button, Typography } from '@material-ui/core';
import { useTheme, makeStyles } from '@material-ui/core/styles';
// import { format } from 'date-fns';
import ChevronLeftIcon from '@assets/chevron-left.svg';
import { PageTypeContextProvider, PAGE_TYPE_ARTICLE } from '@context/PageTypeContext';
import { useColorMode } from '@context/ColorModeContext';
import { SET_COLOR_MODE } from '@context/ColorModeContext/actions';
import { EntryContextProvider } from '@context/EntryContext';
import Layout from '@c/Layout';
import VisibilitySensor from '@c/VisibilitySensor';
import ArticleContent from '@c/ArticleContent';
import Divider from '@c/Divider';
import RelatedEntries from '@c/RelatedEntries';
import AuthorInfo from '@c/AuthorInfo';

const useStyles = makeStyles(theme => ({
  category: {
    fontSize: '0.75rem',
    fontWeight: 600,
    textTransform: 'uppercase',
    lineHeight: 1.33,
    letterSpacing: '0.5px',
    [theme.breakpoints.down('md')]: {
      marginTop: theme.spacing(4),
    },
  },
  link: {
    color: theme.colors.dark,
    padding: 0,
    '&:focus': {
      color: transparentize(0.32, theme.colors.dark),
      '& .MuiButton-startIcon svg path': {
        stroke: transparentize(0.32, theme.colors.dark),
      },
    },
  },
}));

const BlogPost = ({
  data: {
    cms: { entries, relatedEntries, blogMainPage, seomatic },
  },
  pageContext: { slug, blogMainPageTitle },
}) => {
  const classes = useStyles(),
    theme = useTheme(),
    [, setBackground] = useColorMode(),
    entry = entries[0],
    title = entry.title,
    // postDate = entry.postDate,
    category = entry.categories[0],
    articleContent = entry.articleContent,
    author = entry.authors[0];

  const handleOnVisible = useCallback(
    id => {
      setBackground({
        type: SET_COLOR_MODE,
        background: { color: theme.colors.bg },
        header: { mode: 'light' },
        visibility: { visibleContentId: id },
      });
    },
    [setBackground, theme.colors.bg]
  );

  return (
    <PageTypeContextProvider value={PAGE_TYPE_ARTICLE}>
      <EntryContextProvider entry={entry}>
        <Layout seomatic={seomatic}>
          <VisibilitySensor id={`blog-post-1-${slug}`} onVisible={handleOnVisible}>
            <Box mt={{ xs: 12, md: 19.5 }}>
              <Container>
                <Grid container justify='center'>
                  <Grid item xs={12} md={10} lg={2}>
                    <Box display='flex'>
                      <Button
                        size='small'
                        startIcon={<ChevronLeftIcon />}
                        component={Link}
                        to={`/${blogMainPage.uri}`}
                        className={classes.link}
                      >
                        {blogMainPageTitle}
                      </Button>
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={10} lg={8}>
                    {/*
                    <div className={classes.category}>
                      {category && `${category.title} — `}
                      {format(new Date(postDate), 'dd.MM.yyyy')}
                    </div> 
                    */}
                    {category && <div className={classes.category}>{category.title}</div>}
                    <Box mt={{ xs: 3, lg: 8 }}>
                      <Typography variant='h1' component='h1'>
                        {title}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12} lg={2}></Grid>
                </Grid>
              </Container>
            </Box>
          </VisibilitySensor>

          <ArticleContent content={articleContent} />

          <VisibilitySensor id={`blog-post-2-${slug}`} onVisible={handleOnVisible}>
            <Box mb={{ xs: 12, md: 19.5 }}>
              <Container>
                <Grid container justify='center'>
                  <Grid item xs={12} md={10} lg={8}>
                    <Divider mb={{ xs: 7, md: 12 }} />
                    <AuthorInfo data={author} />
                  </Grid>
                </Grid>
              </Container>
            </Box>

            <RelatedEntries
              title={`Mer fra ${category ? category.title : ''}`}
              entries={relatedEntries}
            />
          </VisibilitySensor>
        </Layout>
      </EntryContextProvider>
    </PageTypeContextProvider>
  );
};

export default BlogPost;

export const query = graphql`
  query($slug: String!, $relatedEntriesIds: [cms_QueryArgument]!, $path: String!) {
    cms {
      entries(section: "blog", slug: [$slug]) {
        id
        ...blogBaseFields
        ... on cms_blog_blog_Entry {
          articleContent {
            ...articleContentFields
          }
          authors {
            ...userFields
          }
        }
      }
      blogMainPage: entry(section: "blogMainPage") {
        uri
      }
      relatedEntries: entries(id: $relatedEntriesIds) {
        ...blogBaseFields
      }
      seomatic(uri: $path) {
        ...seomaticFields
      }
    }
  }
`;
